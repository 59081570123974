import { Dialog } from "vant";

export default ({ title, content, showCancelButton = false, showConfirmButton = true }) =>
  Dialog.confirm({
    title,
    message: content,
    confirmButtonText: "Xác nhận",
    cancelButtonText: "Hủy bỏ",
    showConfirmButton,
    showCancelButton,
  })