<template>
  <div class="wrap">
    <Nav title="Thêm bạn bè" />
    <Search
      v-model="searchInput"
      placeholder="Vui lòng nhập tài khoản tìm kiếm/số điện thoại di động"
    />
    <div class="btn_wrap">
      <Button type="primary" block @click="search">Nhấp vào tìm kiếm</Button>
    </div>
    <List :data="userList">
      <template v-slot="{ item }">
        <UserItem
          :img="item.userimg"
          class="useritem_wrap"
          :text="item.username"
          @click="handleClick(item)"
        />
      </template>
    </List>
  </div>
</template>

<script>
import { Search, Button, Toast } from "vant";
import Nav from "./components/nav.vue";
import { $post } from "@/utils/request";
import List from "@/components/List.vue";
import UserItem from "./components/userItem.vue";
import Dialog from "@/utils/Dialog";
export default {
  components: { Search, Nav, Button, List, UserItem },
  data() {
    return {
      searchInput: "",
      searchLoading: false,
      userList: [],
    };
  },
  methods: {
    addLoading() {
      this.searchLoading = true;
      Toast.loading({ duration: 0 });
    },
    clearLoading() {
      this.searchLoading = false;
      Toast.clear();
    },
    async search() {
      console.log(this.searchInput, this.searchLoading);
      if (this.searchLoading || !this.searchInput) return;
      this.addLoading();
      try {
        const {
          data: { ret, msg, data },
        } = await $post("/rooms/search/user", { key: this.searchInput });
        this.clearLoading();
        if (ret == 1) {
          const { lists } = data;
          this.userList = lists;
          return;
        }
        Toast(msg);
      } catch (error) {
        console.log(error, "搜索用户错误");
        this.clearLoading();
      }
    },
    handleClick(item) {
      Dialog({
        content: `Có thêm người dùng ${item.username} làm bạn của bạn hay không`,
        showCancelButton: true,
      })
        .then(() => {
          this.addFriend(item.id)
        })
        .catch(() => {});
    },
    async addFriend(userid) {
      Toast.loading();
      try {
        const {
          data: { ret, msg, data },
        } = await $post("/rooms/add/friend", { userid });
        Toast.clear();
        Toast(msg);
        setTimeout(() => {
          if (ret == 1) {
            this.$router.go(-1);
          }
        }, 1000);
      } catch (error) {
        console.log(error, "添加好友错误");
        Toast.clear();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #fff;
  > .btn_wrap {
    padding: 0 24px;
  }
  .useritem_wrap {
    margin: 20px 24px 0;
  }
}
</style>